<template>
  <div class="page">
    <top />
    <div style="width: 100%; height: 55px"></div>
    <div class="main">
      <div class="main-content">
        <div class="browse">
          <img class="img1" :src="imageUrl(clothingPictures)" />
          <div
            v-if="fourConsecutive == true"
            id="img"
            class="logo"
            :style="{
              width: width + 'px',
              height: height + 'px',
              top: top + 'px',
              left: left + 'px',
              backgroundImage: 'url(' + imgs + ')',
              backgroundSize: bcSize + 'px',
            }"
          ></div>
          <img
            v-if="fourConsecutive == false"
            id="img"
            class="logo"
            :src="imgs"
            alt=""
            :style="{
              width: width + 'px',
              height: height + 'px',
              top: top + 'px',
              left: left + 'px',
              transform: `scale(${diploid}, ${diploid})`,
            }"
          />
          <div class="bj" :style="{ backgroundColor: bc }"></div>
        </div>
        <div class="name">{{ archivesInfo.title }}</div>
        <div class="function">
          <div style="font-size: 32px">
            <i
              class="el-icon-caret-top"
              style="cursor: pointer"
              @click="top -= 5"
            ></i>
            <i
              class="el-icon-caret-bottom"
              style="margin-left: 16px; cursor: pointer"
              @click="top += 5"
            ></i>
          </div>
          <div style="font-size: 32px">
            <i
              class="el-icon-caret-left"
              style="cursor: pointer"
              @click="left -= 5"
            ></i>
            <i
              class="el-icon-caret-right"
              style="margin-left: 16px; cursor: pointer"
              @click="left += 5"
            ></i>
          </div>
          <div style="font-size: 30px">
            <i class="el-icon-zoom-in" style="cursor: pointer" @click="add"></i>
            <i
              class="el-icon-zoom-out"
              style="margin-left: 16px; cursor: pointer"
              @click="del"
            ></i>
          </div>
        </div>
        <div class="df_flex">
          <el-progress
            :text-inside="true"
            :stroke-width="25"
            :format="format"
            :percentage="(archivesInfo.sales_num / hot_max) * 100 || 0"
            status="exception"
          ></el-progress>

          <div
            class="Hot"
            :style="{
              left: 50 + `%`,
            }"
          >
            火爆指数
          </div>
        </div>
        <div class="function2">
          <div @click="rect">重置</div>
          <div @click="show = true">细节图</div>
          <div @click="isShow = true">换款式</div>
        </div>
        <div style="margin-top: 15px; border: 1px solid #f1f2f3"></div>
        <div class="author">
          <div @click="goAuthor(archivesInfo.users_id)">
            <img :src="imageUrl(archivesInfo.head_pic)" alt="" />
          </div>
          <div class="second">
            <div>
              上传者：<span style="margin-left: 5px">{{
                archivesInfo.author
              }}</span>
            </div>
            <div style="margin-top: 15px">
              发布时间：<span style="margin-left: 5px">{{
                archivesInfo.update_time
              }}</span>
            </div>
          </div>
          <div class="third" v-if="follow == 'no'" @click="fllowDo(1)">
            关注+
          </div>
          <div class="third" v-else @click="fllowDo(2)">已关注</div>
        </div>
        <div class="information1">
          <div class="top1">
            <div>
              编号<span style="margin-left: 5px; color: #000000">{{
                archivesInfo.aid
              }}</span>
            </div>
            <div style="margin-left: 10px">
              格式<span style="margin-left: 5px; color: #000000">{{
                archivesInfo.format
              }}</span>
            </div>
          </div>
          <div class="bottom1">
            <div>不可商用!</div>
            <div class="question">
              <div @click="goQU('Copyright', 'copyrightissues')">版权问题</div>
              <div class="line"></div>
              <div @click="goQU('Copyright', 'salesproblems')">售后问题</div>
            </div>
          </div>
        </div>
        <div class="vip">
          <div>
			  <span v-if="level != 1">
				原价<span style="color: #888; padding: 0 5px;text-decoration: line-through;">￥{{ archivesInfo.line_price }}</span>
			  </span>
            价格<span style="font-size: 14px; margin-left: 10px; color: #e66617"
              >￥{{ archivesInfo.users_price }}</span
            >
          </div>
          <div v-if="level == 1" @click="go('Vip')">点击成为会员即享优惠</div>
        </div>
        <div class="buy">
          <div v-if="!isShows" @click="getShopInfo">下载源文件</div>
          <div v-else>下载中</div>
          <div style="display: flex; align-items: center">
            <img src="../../assets/car.png" alt="" />
            <div @click="joinCartDo">加入购物车</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 细节图开始 -->
    <el-dialog :visible.sync="show" width="260px" style="margin-top: 60px">
      <div class="show">
        <img :src="imageUrl(archivesInfo.litpic_watermark)" alt="" />
      </div>
    </el-dialog>
    <!-- 款式弹框 -->
    <el-dialog
      title=""
      :visible.sync="isShow"
      width="80%"
      style="margin-top: 100px"
      center
    >
      <div class="styleDesign">
        <div
          v-for="(item, index) in clothingTem"
          :key="index"
          @click="change(index)"
        >
          <img :src="imageUrl(item.arctype_clothes_img)" alt="" />
        </div>
      </div>
    </el-dialog>
    <!-- 网盘链接弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="merchandise"
      width="300px"
      style="margin-top: 60px"
      center
    >
      <el-form label-position="left" label-width="80px">
        <el-form-item label="网盘链接"> {{ panUrl }} </el-form-item>

        <el-form-item label="网盘密码"> {{ panPass }} </el-form-item>
      </el-form>
    </el-dialog>
    <div class="bottom">
      <div style="font-size: 14px; color: #333333">作者其他作品</div>
      <div class="bottomimg">
        <div v-for="(item, index) in authorAbout" :key="index">
          <img
            :src="imageUrl(item.litpic)"
            @click="goDetail('Details', item.aid)"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="special">
      <div class="special-warp">
        <div>热门设计师</div>
      </div>
      <div class="special-content">
        <div
          v-for="(item, index) in designersData"
          :key="index"
          v-if="index < 6"
        >
          <div class="special-item1" @click="goAuthor(item.users_id)">
            <div>
              <img :src="imageUrl(item.head_pic)" alt="" />
            </div>
            <div class="name">{{ item.nickname }}</div>
            <div class="more">查看更多</div>
          </div>
        </div>
      </div>
    </div>
    <info />
    <div style="width: 100%; height: 60px; background-color: #f6f6f6"></div>
    <foot />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import foot from "../../components/common/footer.vue";
import info from "../../components/common/qrCode.vue";
import {
  openAreaDetail,
  fllowDo,
  joinCart,
  sourceDetailorder,
  checkUserOrder,
  checkUserOrders,
  upcolor
} from "../../request/api";
export default {
  components: {
    top,
    foot,
    info,
  },
  data() {
    return {
      count: 30,
      size: 10,
      page: 1,
      aid: "",
      archivesInfo: "", //logo 信息
      authorAbout: "", //作者其他作品
      designersData: "", //热门设计师
      clothingTem: "", //衣服
      follow: "", //是否关注
      active: 0, //衣服选中
      top: 100.75,
      left: 116.25,
      width: 93,
      height: 93,
      diploid: 1, //放大倍数
      show: false,

      index: 0, //当前选中标识
      level: "", //用户等级
      merchandise: false, //商品弹框
      panUrl: "",
      panPass: "",
      imgs: null,
      bc: null,
      fourConsecutive: false,
      bcSize: 100,
      isShow: false,
      clothingPictures: "",
      isShows: false,
      downloadUrl: "",
      hot_max: "",
    };
  },
  created() {
    this.aid = this.$route.query.aid;
    this.getData();
  },
  methods: {
    //获取数据
    getData() {
      openAreaDetail({
        aid: this.aid,
      }).then((res) => {
        if(res.code!=200){
        	this.$router.replace({path:'/'});
        	return false;
        }
        this.archivesInfo = res.data.archivesInfo;
        this.authorAbout = res.data.authorAbout;
        this.designersData = res.data.designersData;
        this.clothingTem = res.data.clothingTem;
        this.clothingPictures =
          this.clothingTem[this.active].arctype_clothes_img;
        this.hot_max = res.data.hot_max;
        console.log(this.clothingPictures);
        this.follow = res.data.follow;
        this.level = res.data.level;
        let result = res.data.archivesInfo.typeid.split(",");
        if (this.archivesInfo.repeat != undefined) {
          this.top = 0;
          this.left = 0;
          this.width = 341;
          this.height = 341;
          this.fourConsecutive = true;
        }
        this.imgs = this.imageUrl(this.archivesInfo.litpic);
        this.getBodymovinJson();
      });
    },
    //关注操作
    fllowDo(type) {
      fllowDo({
        aid: this.archivesInfo.users_id,
        type: type,
      }).then((res) => {
        if (res.code == 200) {
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //加入购物车
    joinCartDo() {
      joinCart({
        aid: this.aid,
        type: 1,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    change(index) {
      this.active = index;
      this.clothingPictures = this.clothingTem[this.active].arctype_clothes_img;
      this.isShow = false;
    },
    goQU(url, type) {
      this.$router.push({
        name: url,
        query: {
          type: type,
        },
      });
    },
    format(percentage) {
      return percentage === 100
        ? `${this.archivesInfo.sales_num}`
        : `${this.archivesInfo.sales_num}`;
    },
    go(url) {
      this.$router.push({
        name: url,
      });
    },
    goAuthor(author_id) {
      this.$router.push({
        name: "Author",
        query: {
          author_id: author_id,
        },
      });
    },
    goDetail(url, aid) {
      this.$router.push({
        name: url,
        query: {
          aid: aid,
        },
      });
      this.$router.go(0);
    },
    //分页
    handleCurrentChange(val) {
      this.page = val;
    },
    //重置
    rect() {
      if (this.fourConsecutive == true) {
        this.diploid = 1;
        this.top = 0;
        this.left = 0;
        this.width = 341;
        this.height = 341;
        this.bcSize = 100;
      } else {
        this.top = 65;
        this.left = 75;
        this.diploid = 1;
      }
    },
    add() {
      if (this.fourConsecutive == true) {
        this.bcSize = 100;
        this.diploid += 0.1;
        this.bcSize = this.bcSize * this.diploid;
        this.width = this.width * (this.bcSize / 100);
        this.height = this.height * (this.bcSize / 100);
      } else {
        this.diploid += 0.1;
      }
    },
    del() {
      if (this.fourConsecutive == true) {
        this.bcSize = 100;
        this.diploid -= 0.1;
        this.bcSize = this.bcSize * this.diploid;
        this.width = this.width * (100 / this.bcSize);
        this.height = this.height * (100 / this.bcSize);
      } else {
        this.diploid -= 0.1;
      }
    },
    downloadWithBlob(url) {
      this.isShows = true;
      fetch(url).then((res) =>
        res.blob().then((blob) => {
          this.isShows = false;
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          var filename =
            this.archivesInfo.title +
            this.downloadUrl.substring(this.downloadUrl.lastIndexOf("."));
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
    },
    //查询是否购买
    getShopInfo() {
      checkUserOrder({
        aid: this.aid,
      }).then((res) => {
        if (res.code == 200) {
          this.panUrl = res.data.panurl;
          this.panPass = res.data.panpwd;
          this.downloadUrl = res.data.raw_litpic;
          if (res.data.raw_litpic == null || res.data.raw_litpic == "") {
            this.merchandise = true;
          } else {
            this.downloadWithBlob(res.data.raw_litpic);
          }
        }
        if (res.code == 300) {
          this.$confirm(res.msg, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            checkUserOrders({
              aid: this.aid,
            }).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.downloadUrl = res.data.raw_litpic;
                this.downloadWithBlob(res.data.raw_litpic);
              }

              if (res.code == 500) {
                this.$message.error(res.msg);
              }
            });
          });
        }
        if (res.code == 500) {
          this.upload();
        }
      });
    },
    //下载
    upload() {
      sourceDetailorder({
        aid: this.aid,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });

          this.$router.push({
            name: "Order",
          });
        }

        if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },
    getBodymovinJson() {
      var that = this;
      let image = that.imageUrl(that.archivesInfo.litpic);
      this.getBase64(image, (res) => {
        // console.log(res);
        that.imgs = res;
        setTimeout(function () {
          that.initCanvas();
        }, 500);
      });
    },
    getBase64(url, callback) {
      //通过构造函数来创建的 img 实例，在赋予 src 值后就会立刻下载图片，相比 createElement() 创建 <img> 省去了 append()，也就避免了文档冗余和污染
      var Img = new Image(),
        dataURL = "";
      Img.src = url + "?v=" + Math.random(); // 处理缓存,fix缓存bug,有缓存，浏览器会报错;
      Img.setAttribute("crossOrigin", "Anonymous"); // 解决控制台跨域报错的问题
      Img.onload = function () {
        //要先确保图片完整获取到，这是个异步事件
        var canvas = document.createElement("canvas"), //创建canvas元素
          width = Img.width, //确保canvas的尺寸和图片一样
          height = Img.height;
        canvas.width = width;
        canvas.height = height;
        console.log(Img);
        console.log(width);
        console.log(height);
        canvas.getContext("2d").drawImage(Img, 0, 0, width, height); //将图片绘制到canvas中
        dataURL = canvas.toDataURL("image/png"); //转换图片为dataURL
        callback ? callback(dataURL) : null; //调用回调函数
      };
    },
    initCanvas() {
      var that = this;
      var img = document.getElementById("img");
      // var output = document.getElementById('output');
      var canvas = this.draw(img);
      var x = 0;
      var y = 0;
      var color = canvas.getColor(x, y).rgba;
      // document.body.style.background = color;
      console.log(color);
      that.bc = color;
	  that.ucolor(color);
      canvas.canvas.remove();
      // output.innerHTML = '坐标点颜色：' + color;
    },
	ucolor(color){
		upcolor({
			aid: this.aid,
			color:color
		}).then((res) => {})
	},
    draw(img) {
      var style = window.getComputedStyle(img);
      var width = parseInt(style.width, 10);
      var height = parseInt(style.height, 10);
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      document.body.appendChild(canvas);
      // 获取坐标颜色
      function getPixelColor(x, y) {
        var imageData = ctx.getImageData(x, y, 1, 1);
        var pixel = imageData.data;
        console.log(pixel);
        var r = pixel[0];
        var g = pixel[1];
        var b = pixel[2];
        var a = pixel[3] / 255;
        a = Math.round(a * 100) / 100;
        var rHex = r.toString(16);
        r < 16 && (rHex = "0" + rHex);
        var gHex = g.toString(16);
        g < 16 && (gHex = "0" + gHex);
        var bHex = b.toString(16);
        b < 16 && (bHex = "0" + bHex);
        var rgbaColor = "rgba(" + r + "," + g + "," + b + "," + a + ")";
        var rgbColor = "rgb(" + r + "," + g + "," + b + ")";
        var hexColor = "#" + rHex + gHex + bHex;

        return {
          rgba: rgbaColor,
          rgb: rgbColor,
          hex: hexColor,
          r: r,
          g: g,
          b: b,
          a: a,
        };
      }
      return {
        getColor: getPixelColor,
        canvas: canvas,
      };
    },
  },
};
</script>

<style lang="less">
.page {
  background: #f6f6f6;
  .main {
    margin-top: 13px;
    padding: 0px 9px;
    .main-content {
      padding: 15px 8px 22px 8px;
      background-color: #ffffff;
      .browse {
        width: 341px;
        height: 341px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        // text-align: center;
        .img1 {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
        }
        .logo {
          position: absolute;
          transition: all 0.5s ease;
          z-index: 1;
		  object-fit: contain;
        }
        // .bj {
        //   z-index: 1111;
        //   width: 100%;
        //   height: 100%;
        // }
      }
      .name {
        margin-top: 10px;
        text-align: center;
        font-size: 15px;
      }
      .function {
        margin: 16px auto 0 auto;
        width: 86%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #eaeaea;
        padding: 10px 15px;
      }
      .function2 {
        width: 78%;
        margin-left: 11%;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        color: #1a3c59;

        div {
          border: 1px #e1e1e1 solid;
          padding: 12px 16px;
          color: #1a3c59;
          border-radius: 2px;
        }
      }
      .author {
        font-size: 12px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .second {
          position: absolute;
          left: 38%;
          transform: translateX(-38%);
        }
        .third {
          border-radius: 11px;
          background-color: #08243c;
          color: #ffffff;
          padding: 5px 14px;
        }
        img {
          border-radius: 50%;
          width: 50px;
          height: 50px;
        }
      }
      .information1 {
        margin-top: 16px;
        font-size: 12px;
        color: #666666;
        .top1 {
          display: flex;
          align-items: center;
        }
        .bottom1 {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .question {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 120px;
            .line {
              width: 1px;
              height: 7px;
              background-color: #c6c6c6;
            }
          }
        }
      }
      .vip {
        font-size: 12px;
        margin-top: 17px;
        background-color: #f1f2f3;
        padding: 15px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .buy {
        margin-top: 21px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        div:first-child {
          color: #e66617;
          background-color: #f9f0ea;
          padding: 13px 26px;
          border-radius: 2px;
          border: 1px #e66617 solid;
        }
        div:last-child {
          color: #ffffff;
          background-color: #e66617;
          padding: 7px 16px;
          border-radius: 2px;
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .bottom {
    margin-top: 20px;
    padding: 10px;
    .bottomimg {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      img {
        width: 170px;
        height: 170px;
		object-fit: contain;
        margin-bottom: 10px;
      }
    }
    .page {
      position: relative;
      left: -15%;
      transform: scale(0.6);
    }
  }
  .special {
    width: 96%;
    margin: 0 auto;
    margin-top: 20px;
    .special-warp {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .special-content {
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .special-item {
        margin-bottom: 10px;
        width: 48%;
        height: 185px;
        background: #ffffff;
        border-radius: 3px;
        cursor: pointer;

        img {
          width: 100%;
          height: 154px;
          display: block;
        }

        .special-text {
          background-color: #ffffff;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          text-align: center;
          line-height: 31px;
        }
      }
      .special-item1 {
        margin-bottom: 10px;
        width: 167px;
        height: 195px;
        background: #ffffff;
        border-radius: 3px;
        cursor: pointer;
        text-align: center;
        img {
          border-radius: 50%;
          width: 74px;
          height: 74px;
          margin-top: 15px;
        }
        .name {
          font-size: 12px;
          margin-top: 20px;
          color: #08243c;
        }
        .more {
          margin: 0 auto;
          width: 60px;
          border: 1px solid #d2d7dc;
          border-radius: 10px;
          padding: 5px 15px;
          font-size: 12px;
          margin-top: 30px;
          color: #e66617;
        }
      }
    }
  }
  .styleDesign {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    div {
      border: 1px solid #d9d9d9;
      padding: 5px;
      margin: 5px 0;
    }
    img {
      width: 90px;
      height: 90px;
    }
  }
  // 细节图
  .show {
    padding: 0 10px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
.df_flex {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.loader {
  // width: 40%;
  width: 75%;
  height: 25px;
  border-radius: 14px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #999;
  background: #ccc;
  overflow: hidden;
  z-index: 5;
  // top: 90.5%;
  // left: 25%;
}
.yellow .progress-bar {
  background: #f1c40f;
}
.el-progress {
  //   width: 300px;
  width: 75%;
}
.Hot {
  position: absolute;
  top: 30px;
  width: fit-content;
  transform: translateX(-50%);
}
.progress-bar {
  height: inherit;
  width: 0%;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}
.yellow .progress-stripes {
  color: #f39c12;
}

.progress-stripes {
  width: inherit;
  height: inherit;
  font-size: 180px;
  font-weight: bold;
  margin-top: -50px;
  letter-spacing: -14px;
}
.yellow .percentage {
  color: #fff;
}
.percentage {
  position: absolute;
  top: 4px;
  right: 5px;
  font-weight: bold;
  font-size: 16px;
}
.el-message-box {
  width: 80%;
}
</style>
